import React, { useState } from 'react';
import EditarProposta from './PerfilProposta/EditarProposta';
import PartilharClienteProposta from './PT/PartilharClienteProposta';
import AssociarPropostaAoDeal from './HubspotProposta/AssociarProposta';
import { MdEdit } from 'react-icons/md';
import { Button, Drawer, Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa6';

export default function AssociarPartilharProposta({
    isvIucData,
    setIsvIucData,
    valoresTotais,
    setValoresTotais,
    setPropostaInfo,
    propostaInfo,
    dataUser,
    dataCarros,
    setHubspotInfo,
    formatarNumero,
    hubspotInfo,
}) {
    const { t } = useTranslation();

    const [drawerVisibleEditProposta, setDrawerVisibleEditProposta] = useState(false);
    const [drawerVisibleViews, setDrawerVisibleViews] = useState(false);

    const quantidadeAcessos = propostaInfo?.outros?.ultimos_acessos?.length || 0;

    // console.log(propostaInfo)
    // console.log(hubspotInfo)

    const renderEditarPropostaButton = () => (
        <Button
            type="primary"
            onClick={() => setDrawerVisibleEditProposta(true)}
            className="shadow-none font-bold text-black bg-white"
        >
            <div className="flex items-center gap-2">
                <MdEdit />
                {t('gerar_proposta.editar_proposta')}
            </div>
        </Button>
    );

    const renderEditarPropostaDrawer = () => (
        <Drawer
            title={t('editar_proposta.titulo_principal')}
            placement="right"
            className="detalhes-de-log-email"
            onClose={() => setDrawerVisibleEditProposta(false)}
            open={drawerVisibleEditProposta}
        >
            <div className="flex flex-col mb-6">
                <EditarProposta
                    valoresTotais={valoresTotais}
                    setValoresTotais={setValoresTotais}
                    propostaInfo={propostaInfo}
                    setPropostaInfo={setPropostaInfo}
                    dataUser={dataUser}
                    dataCarros={dataCarros}
                    isvIucData={isvIucData}
                    setIsvIucData={setIsvIucData}
                    formatarNumero={formatarNumero}
                    hubspotInfo={hubspotInfo}
                    drawerVisibleEditProposta={drawerVisibleEditProposta}
                />
            </div>
        </Drawer>
    );

    const renderUltimosAcessosDrawer = () => (
        <Drawer
            title={t('tabela_viatura.ultimos_acessos')}
            placement="right"
            className="detalhes-de-log-email"
            onClose={() => setDrawerVisibleViews(false)}
            open={drawerVisibleViews}
        >
            <div className="flex flex-col mb-6">
                <p className="mb-6 font-semibold">{t('tabela_viatura.mais_recente')}:</p>
                <Timeline>
                    {quantidadeAcessos > 0 ? (
                        propostaInfo.outros.ultimos_acessos
                            .slice()
                            .reverse()
                            .map((acesso, index) => (
                                <Timeline.Item key={index}>
                                    <p>
                                        <strong>{t('tabela_viatura.tipo_acesso')}</strong><br /> {acesso.tipo_acesso}
                                    </p>
                                    <p>
                                        <strong>{t('tabela_viatura.hora_acesso')}</strong><br /> {acesso.hora_acesso}
                                    </p>
                                </Timeline.Item>
                            ))
                    ) : (
                        <p>{t('tabela_viatura.sem_acessos_recentes')}</p>
                    )}
                </Timeline>
            </div>
        </Drawer>
    );

    return (
        <div className="flex gap-4">

            {hubspotInfo.idDoDeal !== "" && (
                <div className="flex gap-4 justify-between w-full">
                    {renderEditarPropostaButton()}
                    {renderEditarPropostaDrawer()}
                    <PartilharClienteProposta
                        dataCarros={dataCarros}
                        valoresTotais={valoresTotais}
                        formatarNumero={formatarNumero}
                        propostaInfo={propostaInfo}
                        titulo={true}
                        propostas={false}
                        dataUser={dataUser}
                    />
                </div>
            )}

            {hubspotInfo.idDoDeal == "" && propostaInfo.tipoProposta !== "proposta_cliente" && (
                <div className="flex gap-4 justify-between w-full">
                    {renderEditarPropostaButton()}
                    {renderEditarPropostaDrawer()}
                    <PartilharClienteProposta
                        dataCarros={dataCarros}
                        valoresTotais={valoresTotais}
                        formatarNumero={formatarNumero}
                        propostaInfo={propostaInfo}
                        titulo={true}
                        propostas={false}
                        dataUser={dataUser}
                    />
                </div>
            )}

            {hubspotInfo.idDoDeal == "" && propostaInfo.tipoProposta === "proposta_cliente" && (
                <div className="flex gap-4 justify-between w-full">
                    {renderEditarPropostaButton()}
                    {renderEditarPropostaDrawer()}
                    <AssociarPropostaAoDeal
                        dataUser={dataUser}
                        dataCarros={dataCarros}
                        setHubspotInfo={setHubspotInfo}
                        hubspotInfo={hubspotInfo}
                        setPropostaInfo={setPropostaInfo}
                        propostaInfo={propostaInfo}
                        tituloCTA={t('gerar_proposta.associar_proposta')}
                        descricao={t('gerar_proposta.associar_proposta_deal')}
                    />
                </div>
            )}

            <div
                className={`flex items-center gap-2 px-2 rounded ${quantidadeAcessos > 0 ? 'hover:bg-[#000000] cursor-pointer' : ''
                    }`}
                onClick={quantidadeAcessos > 0 ? () => setDrawerVisibleViews(true) : null}
            >
                <FaEye size={20} />
                <p>{quantidadeAcessos}</p>
            </div>

            {renderUltimosAcessosDrawer()}
        </div>
    );
}
