import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { message, Spin } from 'antd';
import CardDestaque from '../../AllCards/CardDestaque';
import flagPortugal from '../../../assets/flags/flag-portugal.svg';
import flagSpain from '../../../assets/flags/flag-spain.svg';

export default function AnalyticsAutomacoes({ dataUser, filters }) {
    const { t } = useTranslation();
    const [data, setData] = useState({ analytics: {} }); // Inicializa como um objeto para evitar erros
    const [loading, setLoading] = useState(true); // Estado para controle de loading

    useEffect(() => {
        const obterData = {
            data_inicio: filters?.dataCriacaoInicio || null,
            data_fim: filters?.dataCriacaoFim || null,
            lang: dataUser?.language || 'pt',
            user_id: dataUser?.iDTokenAuthenticated || null,
            token: dataUser?.saveToken || '',
            automacoes: 1
        };

        const fetchData = async () => {
            setLoading(true); // Ativa loading antes da requisição
            const url = `${process.env.REACT_APP_GET_ANALYTICS_TABELA}`;

            try {
                const response = await axios.get(url, { params: obterData });

                if (response.data.sucesso) {
                    setData({ analytics: response.data.data.automacoes || {} });
                } else {
                    message.warning(response.data.mensagem);
                }
            } catch (error) {
                console.error('Erro na requisição:', error);
                message.error('Erro ao carregar os dados.');
            } finally {
                setLoading(false); // Desativa loading após a requisição
            }
        };

        fetchData();
    }, [dataUser?.iDTokenAuthenticated, dataUser?.language, filters]); // Mantém dependências seguras

    return (
        <div className="grid grid-cols-4 gap-4">
            {loading ? (
                <>
                    <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                        <div className="h-24"></div>
                    </div>
                    <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                        <div className="h-24"></div>
                    </div>
                    <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                        <div className="h-24"></div>
                    </div>
                    <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                        <div className="h-24"></div>
                    </div>
                    <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                        <div className="h-24"></div>
                    </div>
                    <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                        <div className="h-24"></div>
                    </div>
                    <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                        <div className="h-24"></div>
                    </div>
                    <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                        <div className="h-24"></div>
                    </div>
                </>
            ) : (
                (dataUser.role === 'administrator' ? ['pt', 'es'] : [dataUser.language]).map(language =>
                    (data.analytics?.atual?.[language] || []).map(item => (
                        <CardDestaque
                            key={`${language}-${item.slug}`}
                            text={item?.label}
                            number={item?.number}
                            comparadorNumero={data.analytics?.comparacoes?.[language]?.[`${item.slug}_numero`] || 0}
                            comparadorPercentagem={data.analytics?.comparacoes?.[language]?.[`${item.slug}_percentagem`] || '0%'}
                            icon={language === 'pt' ? flagPortugal : flagSpain}
                            style={true}
                            automacao={true}
                        />
                    ))
                )
            )}
        </div>
    );
}
