import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select, DatePicker, message } from 'antd';
import ChartPropostas from '../../components/Charts/ChartsPropostas';
import { useTranslation } from 'react-i18next';
import TableAnalyticsUsers from '../../components/Dashboard/TableAnalyticsUsers';
import TitleSection from '../../components/Elements/Title';
import CardDestaque from '../../components/AllCards/CardDestaque';
import { HiRefresh } from 'react-icons/hi';
import { FaCheckCircle } from 'react-icons/fa';

const { RangePicker } = DatePicker;

export function Analytics({
    dataUser,
}) {

    const { t } = useTranslation();

    const [filtersSelected, setFiltersSelected] = useState([]); //Filtros selecionados
    const [loading, setLoading] = useState([]);
    const [data, setData] = useState({
        propostas: [],
        analytics: [],
        destaques: [],
        charts: [],
        comparar_com: ''
    });

    const [dateRange, setDateRange] = useState([null, null]); // Estado separado para dateRange

    const [filter, setFilter] = useState({
        dia: [],
        mes: [],
        dateRange: [null, null],
        ano: [],
        user: [],
        dataQuadro: 'today',
        labelDataQuadro: t('tempo.hoje')
    });


    useEffect(() => {


        const fetchData = async () => {
            setLoading(true)

            let url = `${process.env.REACT_APP_GET_ANALYTICS_TABELA}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&mes=${filter.dataQuadro}`;

            const { id_criou, paisPropostas, dataCriacaoInicio, dataCriacaoFim } = filtersSelected;

            if (id_criou !== undefined) url += `&id_criou=${id_criou.toString()}`; // Se necessário, converta para string
            if (paisPropostas !== undefined) url += `&pais=${paisPropostas}`;
            if (dataCriacaoInicio) url += `&data_inicio=${dataCriacaoInicio}`;
            if (dataCriacaoFim) url += `&data_fim=${dataCriacaoFim}`;

            try {
                const response = await axios.get(url);
                //console.log(response)
                if (response.data.sucesso) {
                    setData(prev => ({
                        ...prev,
                        propostas: response.data.data.user_data,
                        analytics: response.data.data.automacoes,
                        destaques: response.data.data.destaques,
                        comparar_com: response.data.data.data_comparacao
                    }));
                } else {
                    message.warning(response.data.mensagem)
                }



                // console.log(response.data.data)
                setLoading(false)

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();

    }, [dataUser.iDTokenAuthenticated, filtersSelected, filter.dataQuadro]);

    useEffect(() => {

        const fetchData = async () => {

            let url = `${process.env.REACT_APP_GET_ANALYTICS_TABELA_PARA_PESQUISAR_DATA_SEMPRE}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&propostas_por_mes=1`;

            try {
                const response = await axios.get(url);

                if (response.data.sucesso) {
                    setData(prev => ({
                        ...prev,
                        grafico_propostas: response.data.data.propostas_por_mes
                    }));
                } else {
                    message.warning(response.data.mensagem)
                }

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();

    }, [dataUser.iDTokenAuthenticated]);

    const handleDateChange = (dates, dateStrings) => {
        setDateRange(dates);
        setFiltersSelected(prev => ({
            ...prev,
            dataCriacaoInicio: dateStrings[0] || null,
            dataCriacaoFim: dateStrings[1] || null,
        }));
    };

    return (
        <>

            <div className="grid gap-4 w-full">

                <div className='grid card gap-4'>

                    <TitleSection
                        title="Analytics"
                        description=''
                    />

                    <div className='flex gap-2 items-center'>
                        <span className='text-white text-sm font-medium'>{t('dashboard.filtrar_por')}:</span>
                        <Select
                            placeholder={'Selecione o período'}
                            className="select-filters capitalize"
                            defaultValue={filter.dataQuadro}
                            onSelect={(value, option) => {
                                setFilter({
                                    ...filter,
                                    dataQuadro: value,
                                    labelDataQuadro: option.children
                                });

                                // Limpa o dateRange quando um filtro predefinido é selecionado
                                if (value !== "personalizado") {
                                    setDateRange([null, null]);
                                    setFiltersSelected(prev => ({
                                        ...prev,
                                        dataCriacaoInicio: null,
                                        dataCriacaoFim: null,
                                    }));
                                }
                            }}
                            popupMatchSelectWidth={false}
                        >
                            <Select.Option key="today" value="today" className="capitalize">
                                {t('tempo.hoje')}
                            </Select.Option>
                            <Select.Option key="this_week" value="this_week" className="capitalize">
                                {t('tempo.esta_semana')}
                            </Select.Option>
                            <Select.Option key="this_month" value="this_month" className="capitalize">
                                {t('tempo.este_mes')}
                            </Select.Option>
                            <Select.Option key="last_month" value="last_month" className="capitalize">
                                {t('tempo.mes_passado')}
                            </Select.Option>
                            <Select.Option key="last_three_months" value="last_three_months" className="capitalize">
                                {t('tempo.ultimos_3_meses')}
                            </Select.Option>
                            <Select.Option key="all" value="all" className="capitalize">
                                {t('tempo.sempre')}
                            </Select.Option>
                            <Select.Option key="personalizado" value="personalizado" className="capitalize">
                                {t('tempo.personalizado')}
                            </Select.Option>
                        </Select>
                        {filter.dataQuadro == "personalizado" && (

                            <RangePicker
                                value={dateRange}
                                onChange={handleDateChange}
                                placeholder={['Data de início', 'Data de fim']}
                            />

                        )}
                    </div>

                    <hr></hr>

                    <div className='grid grid-cols-3 gap-4'>

                        <CardDestaque
                            text={t('dashboard.propostas_criadas')}
                            number={data.destaques?.atual?.propostas_geradas}
                            comparadorNumero={data.destaques?.comparacao?.propostas_geradas_numero}
                            comparadorPercentagem={data.destaques?.comparacao?.propostas_geradas_percentagem}
                            icon={<HiRefresh size={20} />}
                            style={true}
                            labelDataQuadro={filter.labelDataQuadro}
                            comparar={data.comparar_com}
                        />

                        <CardDestaque
                            text={t('dashboard.propostas_associadas_hoje')}
                            number={data.destaques?.atual?.propostas_associadas}
                            comparadorNumero={data.destaques?.comparacao?.propostas_associadas_numero}
                            comparadorPercentagem={data.destaques?.comparacao?.propostas_geradas_percentagem}
                            icon={<FaCheckCircle size={20} />}
                            style={true}
                            labelDataQuadro={filter.labelDataQuadro}
                            comparar={data.comparar_com}
                        />

                        <CardDestaque
                            text={t('dashboard.propostas_aceites_hoje')}
                            number={data.destaques?.atual?.propostas_aceites}
                            comparadorNumero={data.destaques?.comparacao?.propostas_aceites_numero}
                            comparadorPercentagem={data.destaques?.comparacao?.propostas_aceites_percentagem}
                            icon={<FaCheckCircle size={20} />}
                            style={true}
                            labelDataQuadro={filter.labelDataQuadro}
                            comparar={data.comparar_com}
                        />

                        <CardDestaque
                            text={t('dashboard.doc_por_submeter')}
                            number={data.destaques?.atual?.documentacao_por_submeter}
                            comparadorNumero={0}
                            comparadorPercentagem={0}
                            icon={<FaCheckCircle size={20} />}
                            style={true}
                            labelDataQuadro={null}
                            comparar={null}
                        />

                        <CardDestaque
                            text={t('dashboard.doc_por_validar')}
                            number={data.destaques?.atual?.documentacao_por_validar}
                            comparadorNumero={0}
                            comparadorPercentagem={0}
                            icon={<FaCheckCircle size={20} />}
                            style={true}
                            labelDataQuadro={null}
                            comparar={null}
                        />

                        <CardDestaque
                            text={t('dashboard.doc_validada')}
                            number={data.destaques?.atual?.documentacao_validada}
                            comparadorNumero={0}
                            comparadorPercentagem={0}
                            icon={<FaCheckCircle size={20} />}
                            style={true}
                            labelDataQuadro={null}
                            comparar={null}
                        />

                    </div>

                    <hr></hr>

                    <TableAnalyticsUsers
                        data={data.propostas}
                        dataUser={dataUser}
                        filtersSelected={filtersSelected}
                        setFiltersSelected={setFiltersSelected}
                        loading={loading}
                    />

                </div>

                {data.grafico_propostas && (
                    <div className="card grid gap-6">
                        <div className='flex gap-2 items-center'>
                            <p className='text-white text-sm font-medium pb-4'>Total de Propostas por mês</p>
                        </div>
                        <ChartPropostas data={data.grafico_propostas} />
                    </div>
                )}

            </div>

        </>
    )
}
