import React from 'react';
import { Table } from 'antd';
import Loading from '../Elements/Loading';
import NoResults from '../Elements/NoResults';

export default function CustomTable({ columns, data, pagination, loading, users }) {

    if (loading) {
        return (
            <Loading />
        );
    }

    if (!loading && data.length === 0) {
        return <NoResults />;
    }

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}> {/* Habilita apenas a rolagem lateral */}
            <Table
                columns={columns}
                dataSource={data}
                pagination={pagination}
                bordered
                scroll={{ x: 'max-content' }} // Garante rolagem horizontal
                style={{ width: '100%' }}     // Largura total da tabela no container
                tableLayout='auto'
                rowClassName={(record, index) =>
                    users === 1 && index < 3 ? 'highlight-row' : ''
                } // Aplica classe de destaque se users for 1
            />
        </div>
    );
};
