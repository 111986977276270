import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CardDestaque({ text, number, icon, style, comparadorNumero, comparadorPercentagem, labelDataQuadro, link, automacao, comparar }) {

    const { t } = useTranslation();

    // Estado para armazenar o número animado
    // const [displayNumber, setDisplayNumber] = useState(0);
    //console.log(number)
    // Verifica se `number` ainda está carregando (undefined ou null)
    const isLoading = number == undefined || number == null;

    // Define a cor com base no valor de `comparadorPercentagem`
    const percentColor = comparadorPercentagem < 0 ? 'text-red-500' : 'text-green-500';


    const [poupanca, setPoupanca] = useState(0);


    // // Efeito para animar o número crescente
    // useEffect(() => {
    //     if (number && number !== displayNumber) {
    //         let start = 0;
    //         const duration = 1000; // duração da animação em milissegundos
    //         const startTime = performance.now();

    //         const animate = (currentTime) => {
    //             const elapsed = currentTime - startTime;
    //             const progress = Math.min(elapsed / duration, 1); // assegura que o progresso nunca passa de 1
    //             const currentNumber = Math.floor(progress * number);

    //             setDisplayNumber(currentNumber);

    //             if (progress < 1) {
    //                 requestAnimationFrame(animate);
    //             }
    //         };

    //         requestAnimationFrame(animate);
    //     }
    // }, [number]);



    // Efeito para calcular a poupança em horas
    useEffect(() => {
        let minutosPorAutomacao = 1; // Valor padrão para evitar problemas

        if (number) {
            if (text === "Automação Sales" && text === "Automatización de Ventas") {
                minutosPorAutomacao = 1; // 1 minuto por tarefa
            } else if (text === "Automação Operações" && text === "Automatización de Operaciones") {
                minutosPorAutomacao = 5; // 5 minutos por tarefa
            }

            const totalMinutos = number * minutosPorAutomacao; // Total de minutos
            const horas = Math.floor(totalMinutos / 60); // Pegando as horas inteiras
            const minutos = totalMinutos % 60; // Pegando os minutos restantes

            const resultado = `${horas}h ${minutos}min`;
            setPoupanca(resultado);

            console.log(resultado);
        }
    }, [number, text]);


    console.log(text)


    console.log(link)

    return (
        <div>
            {isLoading ? (
                // Estado de carregamento quando `number` é indefinido ou nulo
                <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                    <div className="h-16"></div>
                </div>
            ) : (
                // Exibe o conteúdo quando `number` está disponível
                <div className={`${style ? 'border-card bg-black card-cinza' : 'card'} text-white ${link ? 'hover-border-red hover:shadow-lg transition-shadow duration-300' : ''}`}>
                    <div className="flex w-full flex-row items-center justify-between space-y-0 pb-4">
                        <p className='text-sm font-medium'>{text}</p>
                        {automacao ?
                            <img
                                src={icon}
                                alt="Ícone de automação"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    border: '1px solid rgb(255, 255, 255)',
                                    borderRadius: '50px',
                                    lineHeight: '37px',
                                    fontSize: '18px',
                                    padding: '4px'
                                }}
                            />
                            :
                            <span>{icon}</span>
                        }
                    </div>
                    <div className='flex gap-2 items-center pb-2'>
                        <span className='text-lg font-bold text-center'>
                            {number}
                        </span>
                        <span className={`text-sm font-bold text-center ${percentColor}`}>
                            {/* <Tooltip placement="topRight" title={`${comparadorNumero} ${text}`}> */}
                            <span
                            // style={{ cursor: 'pointer' }}
                            >
                                {comparadorNumero !== 0 ? `${comparadorNumero}` : null}
                            </span>
                            {/* </Tooltip> */}
                        </span>
                    </div>
                    {comparar && (
                        <span className='text-[#7b7b7b]'>{t('dashboard.comp_com')} {comparar}</span>
                    )}
                    {(text === "Automação Sales" || text === "Automação Operações" || text === "Automatización de Ventas" || text === "Automatización de Operaciones") && (
                        <span className='text-[#ffeb3b] font-semibold'>Poupança {poupanca}</span>
                    )}

                </div>
            )}
        </div>
    );
}
