import React, { useState, useEffect } from 'react';
import { Button, Drawer, Select, DatePicker, Slider } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function CustomFilters({ configs, setConfigs, filtersSelected = {}, setFiltersSelected, filters, drawerVisibleFilters, setDrawerVisibleFilters, dataUser }) {

    const { t } = useTranslation();

    const [utilizadores, setUtilizadores] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);

    useEffect(() => {

        if (filters.find(filter => filter.key === 'id_criou')) {
            // Monta a URL para a chamada de API
            const url = `${process.env.REACT_APP_OBTER_UTILIZADORES}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&bussiness_developer=1`;

            // Faz a chamada de API para obter utilizadores
            axios.get(url)
                .then((response) => {
                    setUtilizadores(response.data.mensagem);
                })
                .catch((error) => {
                    console.error('Erro ao obter utilizadores:', error);
                });
        }

    }, []);


    const handleReset = () => {
        setFiltersSelected({});
        setDateRange([null, null]);
    };


    const handleFilterChange = (key, value) => {
        setFiltersSelected(prev => ({ ...prev, [key]: value }));
    };

    const handleDateChange = (dates, dateStrings) => {
        setDateRange(dates);
        setFiltersSelected(prev => ({
            ...prev,
            dataCriacaoInicio: dateStrings[0] || null,
            dataCriacaoFim: dateStrings[1] || null,
        }));
    };


    const closeDrawerFilters = () => {
        setDrawerVisibleFilters(false);
    };

    const handleRangeSlider = (key, value) => {
        setFiltersSelected(prev => ({ ...prev, [key]: value }));
    };


    // const handleQuantidadePedidos = (value) => {
    //     setFiltersSelected((prev) => ({
    //         ...prev,
    //         limitPedidos: value
    //     }));
    // };

    const handleQuantidadePorPagina = (value) => {
        setConfigs((prev) => ({
            ...prev,
            porPagina: value
        }));
    };

    return (

        <Drawer
            title="Filtros"
            placement="right"
            className='detalhes-de-log-email'
            onClose={closeDrawerFilters}
            open={drawerVisibleFilters}
        >
            <div className='flex flex-col mb-6'>

                {Object.keys(filtersSelected).length > 0 && (
                    <>
                        <Button
                            type="primary"
                            className='shadow-none font-bold text-black bg-white mb-6'
                            onClick={handleReset}
                        >
                            {t('tables.limpar_filtros')}
                        </Button>
                    </>
                )}

                {filters.map(filter => {
                    switch (filter.key) {
                        case 'id_criou':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        placeholder={'Business Developer'}
                                        className='select-filters capitalize'
                                        onChange={(value) => handleFilterChange('id_criou', value)}
                                        value={filtersSelected.id_criou || undefined}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {utilizadores.map((utilizador) => (
                                            <Option key={utilizador.id_user} value={utilizador.id_user}>
                                                {utilizador.nome_criou}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            );
                        case 'pais':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Select
                                        placeholder={t('tables.pais')}
                                        className='select-filters capitalize'
                                        onChange={(value) => handleFilterChange('paisPropostas', value)}
                                        value={filtersSelected.paisPropostas || undefined}
                                    >
                                        <Select.Option key="PT" value="pt" className="capitalize">{t('tables.portugal')}</Select.Option>
                                        <Select.Option key="ES" value="es" className="capitalize">{t('tables.espanha')}</Select.Option>
                                    </Select>
                                </div>
                            );
                        case 'estado_proposta':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Select
                                        placeholder={t('tables.estado_proposta')}
                                        className='select-filters capitalize'
                                        onChange={(value) => handleFilterChange('dealHubspotFechado', value)}
                                        value={filtersSelected.dealHubspotFechado || undefined}
                                    >
                                        <Select.Option key="Aberto" value="1" className="capitalize">{t('tables.aceite')}</Select.Option>
                                        <Select.Option key="Fechado" value="0" className="capitalize">{t('tables.aberto')}</Select.Option>
                                    </Select>
                                </div>
                            );
                        case 'tipo_proposta':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Select
                                        placeholder={t('tables.estado_proposta')}
                                        className='select-filters capitalize'
                                        onChange={(value) => handleFilterChange('tipo_proposta', value)}
                                        value={filtersSelected.tipo_proposta || undefined}
                                    >
                                        <Select.Option key="Proposta Cliente" value="proposta_cliente" className="capitalize">{t('tables.proposta_cliente')}</Select.Option>
                                        <Select.Option key="Proposta Intern" value="proposta_interna" className="capitalize">{t('tables.proposta_interna')}</Select.Option>
                                        <Select.Option key="Proposta de Content" value="proposta_de_content" className="capitalize">{t('tables.proposta_de_content')}</Select.Option>
                                    </Select>
                                </div>
                            );
                        case 'data_registo':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <RangePicker
                                        value={dateRange}
                                        onChange={handleDateChange}
                                        placeholder={[t('tables.data_inicio'), t('tables.data_fim')]}
                                    />
                                </div>
                            );
                        case 'range_slider_preco_viatura':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Slider
                                        range
                                        min={0}
                                        max={500000}
                                        step={1000}
                                        value={filtersSelected[filter.key] || [0, 500000]}
                                        onChange={(value) => handleRangeSlider(filter.key, value)}
                                    />
                                </div>
                            );
                        case 'range_slider_taxadeservico':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Slider
                                        range
                                        min={0}
                                        max={50000}
                                        step={1000}
                                        value={filtersSelected[filter.key] || [0, 50000]}
                                        onChange={(value) => handleRangeSlider(filter.key, value)}
                                    />
                                </div>
                            );
                        case 'range_slider_preco_chave_da_mao':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Slider
                                        range
                                        min={0}
                                        max={500000}
                                        step={1000}
                                        value={filtersSelected[filter.key] || [0, 500000]}
                                        onChange={(value) => handleRangeSlider(filter.key, value)}
                                    />
                                </div>
                            );
                        case 'qtd_por_pagina':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Select
                                        placeholder={t('tables.qtd_por_pagina')}
                                        className="select-filters capitalize"
                                        onChange={(value) => handleQuantidadePorPagina(value)}
                                        value={configs.porPagina || undefined}
                                    >
                                        <Select.Option key="20" value="20" className="capitalize">
                                            20
                                        </Select.Option>
                                        <Select.Option key="50" value="50" className="capitalize">
                                            50
                                        </Select.Option>
                                        <Select.Option key="100" value="100" className="capitalize">
                                            100
                                        </Select.Option>
                                    </Select>
                                </div>
                            );
                        case 'total_de_pedidos':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Select
                                        placeholder={t('tables.total_de_pedidos')}
                                        className="select-filters capitalize"
                                        onChange={(value) => handleFilterChange('total_de_pedidos', value)}
                                        value={filtersSelected.total_de_pedidos || undefined}
                                    >
                                        <Select.Option key="100" value="100" className="capitalize">
                                            100
                                        </Select.Option>
                                        <Select.Option key="200" value="200" className="capitalize">
                                            200
                                        </Select.Option>
                                        <Select.Option key="300" value="300" className="capitalize">
                                            300
                                        </Select.Option>
                                    </Select>
                                </div>
                            );

                        case 'estado_documentacao':
                            return (
                                <div key={filter.key} className='flex flex-col mb-6'>
                                    <p className='mb-2 font-semibold'>{filter.label}</p>
                                    <Select
                                        placeholder={t('tables.estado_da_documentacao')}
                                        className="select-filters capitalize"
                                        onChange={(value) => handleFilterChange('estado_documentacao', value)}
                                        value={filtersSelected.estado_documentacao || undefined}
                                    >
                                        <Select.Option key="pending" value="pending" className="capitalize">
                                            {t('tables.doc_por_submeter')}
                                        </Select.Option>

                                        <Select.Option key="validation" value="validation" className="capitalize">
                                            {t('tables.doc_por_validar')}
                                        </Select.Option>
                                        <Select.Option key="completed" value="completed" className="capitalize">
                                            {t('tables.doc_validada')}
                                        </Select.Option>
                                    </Select>
                                </div>
                            );
                        default:
                            return null;
                    }
                })}

            </div>
        </Drawer>

    );
}
